import React from "react";
import {ClipLoader} from "react-spinners";
import './cohortProgress.scss';
import cloudFunction from "../../../util/Firebase/cloudFunction";
import {withFirebase} from "../../../util/Firebase";
import {useNonIdleRefresh} from "../../../util/hooks";
import round from 'lodash/round';

const getCohortStats = (firebase, deploymentId, deviceUserIdentifier=null) =>
    cloudFunction(firebase,`getCohortStats?deploymentId=${deploymentId}&deviceUserIdentifier=${deviceUserIdentifier || ''}`);

export const StatBlock = ({icon, count, description, subDescription}) => {
    return <div className="col mb-2">
        <div className="counter">
            <img src={`/progress/${icon}.png`} style={{width: '100px'}} alt="Cohort Progress" />
            <h2 className="timer count-title count-number">{count === 0 ? "-" : count}</h2>
            <p className="count-text">{description}</p>
            <p className="count-text-sub">{subDescription}</p>
        </div>
    </div>
};

let CohortProgressForUser = ({firebase, deploymentId, deviceUserIdentifier}) => {
    const getData = () => getCohortStats(firebase, deploymentId, deviceUserIdentifier);
    const counters = useNonIdleRefresh(getData);
    return <div className="row">
        <div className="col-12 col-lg-6">
            <h1>Learner's Achievements</h1>
        </div>
        {!counters && <ClipLoader size={75} />}
        {counters &&
        <div className="col-12 col-lg-6">
            <div className="row">
                <div className="col-12 col-md-6">
                    <StatBlock icon="dashboard" count={counters.activities_completed} description="Activities Completed" />
                </div>
                <div className="col-12 col-md-6">
                    <StatBlock icon="sac_words" count={counters.words_learned} description="Words Learned" />
                </div>
                <div className="col-12 col-md-6">
                    <StatBlock icon="book" count={counters.stories_narrated} description="Stories Narrated" />
                </div>
                <div className="col-12 col-md-6">
                    <StatBlock icon="rhyme" count={counters.rhymes_read_aloud} description="Rhymes Read Aloud" />
                </div>
            </div>
        </div>}

    </div>;
};
CohortProgressForUser = withFirebase(CohortProgressForUser);

const CohortProgress = ({counters}) => {
    const wordsPerUserAverage = counters.total_users > 0 ? round(counters.words_learned/counters.total_users, 1) : 0;
    return <div className="row">
        <div className="col-12 col-lg-6">
            <h1>Cohort progress</h1>
            <h5 className="text-muted">Aggregate data for all learners</h5>
        </div>
        {!counters && <ClipLoader size={75} />}
        {counters &&
        <div className="col-12 col-lg-6">
            <div className="row">
                <div className="col-12 col-md-6">
                    <StatBlock icon="dashboard" count={counters.activities_completed} description="Activities Completed" />
                </div>
                <div className="col-12 col-md-6">
                    <StatBlock icon="sac_words" count={wordsPerUserAverage} description="Words Learned (per student average)" />
                </div>
                <div className="col-12 col-md-6">
                    <StatBlock icon="book" count={counters.stories_narrated} description="Stories Narrated" />
                </div>
                <div className="col-12 col-md-6">
                    <StatBlock icon="rhyme" count={counters.rhymes_read_aloud} description="Rhymes Read Aloud" />
                </div>
            </div>
        </div>}

    </div>;
};
export {
    CohortProgress,
    CohortProgressForUser
}