import React, {Component} from 'react'
import PropTypes from 'prop-types';
import Flashcard from '../Flashcard/flashcard';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import FlashcardImageGroup from "../FlashcardImageGroup/FlashcardImageGroup";


export default class PictureDictionary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            modalFlashcards: [],
            showAll: false
        };
    }

    closeModal() {
        this.setState({
            modalOpen: false,
            modalFlashcards: []
        });
    }

    openModal(cards) {
        this.setState({
            modalOpen: true,
            modalFlashcards: cards
        })
    }

    showMore() {
        this.setState({showAll: true});
    }

    render() {
        if (!this.props.groupsToCompletedConcepts) {
            return;
        }

        const groups = Object.values(this.props.groupsToCompletedConcepts).filter(g => g.length > 0);
        const items = this.state.showAll ? groups : groups.slice(0, 4);
        return <div>
            <Modal isOpen={this.state.modalOpen} toggle={this.closeModal.bind(this)} scrollable style={{maxWidth: '70%', height: '90%'}}>
                <ModalHeader toggle={this.closeModal.bind(this)}>Collected words</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        {this.state.modalFlashcards.map(card => {
                            return <div className="col-4 mb-4" key={card.key}>
                                <Flashcard card={card} />
                            </div>
                        })}
                    </div>
                </ModalBody>
            </Modal>
            <div className={`row mt-4`}>
                {items.map((cards, idx) => {
                return <div key={idx} className={`col-3 mb-4`} onClick={() => this.openModal(cards)}>
                    <FlashcardImageGroup flashcards={cards} />
                </div>
            })}
            </div>
            {!this.state.showAll && groups.length > 4 &&
            <button className='btn btn-primary' onClick={this.showMore.bind(this)}>Show more</button>}
        </div>
    }
}
PictureDictionary.propTypes = {
    groupsToCompletedConcepts: PropTypes.array.isRequired
};