import {collectedWordGroupsService, flashcardsService, lessonService} from "../../util/GWUtil/resource";

export const getConceptsBetweenLessons = async (lessonStart, lessonEnd) => {
    let concepts = new Set();
    for (let i = lessonStart; i <= lessonEnd; i++) {
        let lesson = await lessonService.get(i);
        if (lesson.type === "A" || lesson.type === "A1" || lesson.type === "A2" || lesson.type === "A3") {
            lesson.parameters.content.forEach(concepts.add, concepts);
        } else if (lesson.type === "B") {
            lesson.parameters.classes[1].forEach(concepts.add, concepts);
        }
    }
    return Array.from(concepts);
};

export const getGroupToCompletedConcepts = async (lastLessonMastered) => {
    const wordGroups = await collectedWordGroupsService.all();
    const learnedConcepts = lastLessonMastered ? await getConceptsBetweenLessons(1, lastLessonMastered) : [];
    const groups = [];

    // eager populate the flashcards, otherwise each .get() will attempt to perform it.
    await flashcardsService.populateIfNeeded();
    for (const [groupKey, groupConcepts] of Object.entries(wordGroups)) {
        groups[groupKey] = await Promise.all(
            groupConcepts.filter(concept => learnedConcepts.includes(concept))
            .map(async concept => await flashcardsService.get(concept)))
    }
    return groups;
};