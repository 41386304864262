import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

import {withFirebase} from '../../util/Firebase';
import * as ROUTES from '../../constants/routes';
import './index.scss';

const INITIAL_STATE = {
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    }

    onSubmit = event => {
        const {username, email, passwordOne} = this.state;
        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                // Create a user in your Firebase realtime database
                return this.props.firebase.adminAccount(authUser.user.uid).set(
                    {
                        username,
                        email,
                        deployments: {},
                    },
                    { merge: true },
                );
            })
            .then(() => {
                this.setState({...INITIAL_STATE});
                this.props.history.push(ROUTES.LANDING);
            })
            .catch(error => {
                if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                    error.message = ERROR_MSG_ACCOUNT_EXISTS;
                }

                this.setState({error});
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const {
            username,
            email,
            passwordOne,
            passwordTwo,
            error,
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            username === '';

        return (
            <div className="container form-signup">
                <h1 className="h3 mb-3 font-weight-normal">Sign in</h1>
                <form onSubmit={this.onSubmit}>
                    <input
                        name="username"
                        className="form-control"
                        value={username}
                        onChange={this.onChange}
                        type="text"
                        placeholder="First Name"
                    />
                    <input
                        name="email"
                        className="form-control"
                        value={email}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Email Address"
                    />
                    <input
                        name="passwordOne"
                        className="form-control"
                        value={passwordOne}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Password"
                    />
                    <input
                        name="passwordTwo"
                        className="form-control"
                        value={passwordTwo}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Confirm Password"
                    />
                    <button className="btn btn-lg btn-primary btn-block mt-2" disabled={isInvalid} type="submit">
                        Sign Up
                    </button>

                    <div className="h6 text-muted mt-3">
                        {error && <p>{error.message}</p>}
                    </div>
                </form>
            </div>
        );
    }
}

const SignUpLink = () => (
    <div className="mt-3 text-muted">
        Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </div>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpForm;

export {SignUpForm, SignUpLink};
