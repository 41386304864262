import React from "react";
import {withFirebase} from "../../../util/Firebase";
import {ClipLoader} from "react-spinners";
import {Link} from "react-router-dom";
import {useNonIdleRefresh} from "../../../util/hooks";
import {compose} from "recompose";
import {connect} from "react-redux";
import {getHashedUsername, PERMISSION} from "../../../util/permissions";

const Account = ({account, deploymentId}) => {
    return <div className="card">
            <i className='material-icons md-75 card-img-top'>account_circle</i>
            <div className="card-body">
                <h5 className="card-title">{account.username}</h5>
                <Link to={`/deployment/${deploymentId}/user/${account.id}`} className="btn btn-outline-secondary">
                    View profile
                </Link>
            </div>
    </div>;
};

const IndividualLearnerProgress = ({deploymentId, firebase, authUser}) => {
    const permissionLevel = authUser.deployments[deploymentId];
    const getData = () => firebase.deploymentAccounts(deploymentId)
            .get()
            .then(snapshot => snapshot.docs.map(d => {
                const data = d.data();
                //TODO: Move hashing to backend
                data.username = permissionLevel === PERMISSION.ADMIN ? data.username : getHashedUsername(data.username);
                return {id: d.id, ...data};
                }));
    const accounts = useNonIdleRefresh(getData);
    return <div className='text-center'>
        <h1>Individual Learner Progress</h1>
        <h5 className="text-muted mb-5">Click on the user icons below for individual learner analytics</h5>
        {accounts === null && <ClipLoader size={75} />}
        {accounts !== null && <div className='row'>
            {accounts.map(a => {
                return <div className='col-12 col-lg-3' key={a.id}>
                    <Account account={a} deploymentId={deploymentId} />
                </div>
            })}
        </div>}
    </div>
};


const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
});

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        null
    ),
)(IndividualLearnerProgress);