import {useEffect, useState} from "react";
import {useIdle, useInterval} from "react-use";

const IDLE_TIMEOUT_MS = 60 * 1000 * 5; // 5 minutes
const REFRESH_RATE_MS = 30 * 1000; //30s

export function useNonIdleRefresh(dataFunc, refreshRate = REFRESH_RATE_MS, idleTimeout = IDLE_TIMEOUT_MS) {
    // refreshes and sets the data value if the user is not idle.
    const isIdle = useIdle(idleTimeout);
    const [data, setData] = useState(null);
    const doRefresh = () => dataFunc().then(setData);
    useInterval(() => {
        if (!isIdle) {
            doRefresh();
        }
    }, refreshRate);
    useEffect(() => {
        doRefresh();
    }, []);
    return data;
}