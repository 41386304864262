import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import SignOutButton from '../../components/SignOut';
import * as ROUTES from '../../constants/routes';

const Navigation = ({ authUser }) =>
    authUser ? (
        <NavigationAuth authUser={authUser} />
    ) : (
        <NavigationNonAuth />
    );

const NavigationAuth = ({ authUser }) => (
    <div
        className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
        <Link to="/" className="my-0 mr-md-auto font-weight-normal" style={{ textDecoration: 'none', color: '#000' }}>
            <img alt="Logo" src="/GWlogo_spiral_300x195.png" style={{width: '4.5vw', height: '3.0vw'}}/>
        </Link>
        <nav className="my-2 my-md-0 mr-md-3">
            <Link to="/" className="p-2 text-dark font-weight-bold" style={{ textDecoration: 'none', color: '#000' }}>
                Deployments
            </Link>
            <Link to="/feedback" className="p-2 text-dark font-weight-bold" style={{ textDecoration: 'none', color: '#000' }}>
                Feedback
            </Link>
            <Link to="/resources" className="p-2 text-dark font-weight-bold" style={{ textDecoration: 'none', color: '#000' }}>
                Resources
            </Link>
        </nav>
        <SignOutButton />
    </div>
);

const NavigationNonAuth = () => (
    <div
        className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
        <Link to="/" className="my-0 mr-md-auto font-weight-normal" style={{ textDecoration: 'none', color: '#000' }}>
            <img alt="Logo" src="/GWlogo_spiral_300x195.png" style={{width: '4.5vw', height: '3.0vw'}}/>
        </Link>
        <nav className="my-2 my-md-0 mr-md-3">
            <Link to="/" className="p-2 text-dark" style={{ textDecoration: 'none', color: '#000' }}>
                Home
            </Link>
            <Link to="/feedback" className="p-2 text-dark" style={{ textDecoration: 'none', color: '#000' }}>
                Feedback
            </Link>
            <Link to="/resources" className="p-2 text-dark" style={{ textDecoration: 'none', color: '#000' }}>
                Resources
            </Link>
        </nav>
        <Link className="btn btn-outline-primary" to={ROUTES.SIGN_IN}>Sign In</Link>
    </div>
);

const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Navigation);