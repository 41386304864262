import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';

import store from './store';
import App from './pages/App';
import Firebase, { FirebaseContext } from './util/Firebase';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: 'https://83bb15a3bc0744d78115960802e5a432@sentry.io/1777851'});
}

ReactDOM.render(
    <Provider store={store}>
        <FirebaseContext.Provider value={new Firebase()}>
            <App />
        </FirebaseContext.Provider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
