export const activity = {
    LESSON: 'lesson',
    VOCAB: 'vocab',
    RHYME: 'rhyme',
    BEFORE_AFTER_ASSESS: 'before_after_assess',
    VOCAB_ASSESS: 'vocab_assess',
    VOCAB_STRENGTHEN: 'vocab_strengthen',
    PHONIC_ASSESS: 'phonic_assess',
    PHONIC_STRENGTHEN: 'phonic_strengthen',
    ENTERTAINMENT: 'entertainment',
    BOOK: 'book',
    SOUND_CLICK: 'soundClick',
    SOUND_CLICK_1: 'soundClick1',
    IMAGE_CLICK: 'imageClick',
    WORD_CLICK: 'wordClick',
    GLEN_MATCH: 'glenMatch',
    GLEN_WRITE: 'glenWrite',
    DESCRIBE_IT: 'describeIt',
    CONSONANT_SOUNDS: 'consonantSounds'
};