import {useNonIdleRefresh} from "../../util/hooks";
import times from "lodash/times";
import {ClipLoader} from "react-spinners";
import {withFirebase} from "../../util/Firebase";
import React from "react";
import {StatBlock} from "../Deployment/sections/cohortProgress";

const Star = () => <svg viewBox="0 0 50 50" height="60" width="60"><path fill="yellow" stroke="#000" d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"/></svg>
let CohortProgressForUser = ({firebase, account, deploymentId, deviceUserIdentifier, withSpanishTranslation=false}) => {
    const lastLessonMastered = account.last_lesson_mastered ? Math.floor(account.last_lesson_mastered / 5) : 0;
    const getData = () => firebase.getCohortStats(deploymentId, deviceUserIdentifier);
    const counters = useNonIdleRefresh(getData);
    return <div className="row">
        <div className="col-12 col-lg-6">
            <h2>{account.username}'s Achievements</h2>
            {withSpanishTranslation && <h5>Los logros de {account.username}</h5>}
            <div className='mt-3'>
                {times(lastLessonMastered).map((x, i) =>
                    <span key={i} className='p-2'><Star /></span>
                )}
            </div>
        </div>
        {!counters && <ClipLoader size={75} />}
        {counters &&
        <div className="col-12 col-lg-6">
            <div className="row">
                <div className="col-6 col-md-6">
                    <StatBlock icon="dashboard" count={counters.activities_completed} description="Activities" subDescription={withSpanishTranslation ? "Actividades" : null} />
                </div>
                <div className="col-6 col-md-6">
                    <StatBlock icon="sac_words" count={counters.words_learned} description="Words" subDescription={withSpanishTranslation ? "Palabras" : null} />
                </div>
                <div className="col-6 col-md-6">
                    <StatBlock icon="book" count={counters.stories_narrated} description="Stories" subDescription={withSpanishTranslation ? "Cuentos" : null} />
                </div>
                <div className="col-6 col-md-6">
                    <StatBlock icon="rhyme" count={counters.rhymes_read_aloud} description="Rhymes" subDescription={withSpanishTranslation ? "Rimas" : null} />
                </div>
            </div>
        </div>}

    </div>;
};
export default withFirebase(CohortProgressForUser);
