import React from "react";
import {
    ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import {ClipLoader} from "react-spinners";
import {withFirebase} from "../../../util/Firebase";
import moment from 'moment';
import round from 'lodash/round';
import {useNonIdleRefresh} from "../../../util/hooks";


const ScatterGraph = ({data}) => {

    //formatted like {"data":[{"date":"09/30/2019","values":[75,18.75],"mean":46.875}]}
    let scores = [];
    data.forEach(d => {
        const formatted = moment(d.time).format("MM/DD/YYYY");
        const toUnix = moment(formatted, 'MM/DD/YYYY').unix();
        scores.push({x: toUnix, y: round(d.score / 20, 2) * 100, lessonGroup: d.lesson_group})
    });

    const formatter = (value, name, props) =>  {
        if (name === 'date') {
            return [moment.unix(value).format("MM/DD/YYYY"), name]
        }
        return [value, name];
    };

    const oneDaySeconds = 60 * 60 * 24;

    // see https://github.com/recharts/recharts/issues/956
    return <ResponsiveContainer width='75%' height={350} className='ml-auto mr-auto'><ScatterChart
        margin={{
            top: 20, right: 20, bottom: 20, left: 20,
        }}
    >
        <CartesianGrid />
        <XAxis scale="time"
               domain = {[(dataMin) => dataMin - oneDaySeconds, (dataMax) => dataMax + oneDaySeconds]}
               dataKey="x" name="date" type="number" tickFormatter = {(time) => moment.unix(time).format('MM/DD/YYYY')} />
        <YAxis dataKey="y" name="score" unit="%" />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} formatter={formatter} />
        <Scatter data={scores} fill="#8884d8">
        </Scatter>
    </ScatterChart></ResponsiveContainer>
};

const ComprehensiveAssessmentScores = ({firebase, deploymentId, deviceUserIdentifier=null}) => {
    const getData = () => firebase.getPerLessonGroupAssessmentScores(deploymentId, deviceUserIdentifier);
    const data = useNonIdleRefresh(getData);
    return <div className='text-center'>
        <h1>Per lesson group assessment scores over time</h1>
        <h5 className="text-muted mb-5">Scores for the assessment modules embedded in every lesson group</h5>
        {data === null && <ClipLoader size={75} />}
        {data && data.length === 0 && <span>No data yet!</span>}
        {data && data.length > 0 && <ScatterGraph data={data} />}
    </div>
};
export default withFirebase(ComprehensiveAssessmentScores);
