import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import Feedback from '../Static/feedback'
import Resources from '../Static/resources'
import SignUpPage from '../SignUp';
import Deployment from '../Deployment/deployment';
import DeploymentUser from '../DeploymentUser/deploymentUser';
import SignInPage from '../SignIn';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../../util/Session';
import './index.scss'

function RouteWithHeader({...props}) {
    return (<>
            <Navigation/>
            <div className="mb-5">
                <Route {...props} />
            </div>
        </>
    );
}

const App = () => (
    <Router>
        <Switch>
            <RouteWithHeader exact path={ROUTES.LANDING} component={LandingPage} />
            <RouteWithHeader path={ROUTES.SIGN_UP} component={SignUpPage} />
            <RouteWithHeader path={ROUTES.SIGN_IN} component={SignInPage} />
            <RouteWithHeader path={ROUTES.FEEDBACK} component={Feedback} />
            <RouteWithHeader path={ROUTES.RESOURCES} component={Resources} />
            <RouteWithHeader path={ROUTES.DEPLOYMENT_USER} component={DeploymentUser} />
            <RouteWithHeader path={ROUTES.DEPLOYMENT_ID} component={Deployment} />
        </Switch>
    </Router>
);

export default withAuthentication(App);