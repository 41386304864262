import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import cloudFunction from "./cloudFunction";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
      this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
      this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();


  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
      this.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          this.adminAccount(authUser.uid)
              .get()
              .then(snapshot => {
                const dbUser = snapshot.data();

                  // default empty deployments dict
                  if (!dbUser.deployments) {
                      dbUser.deployments = {};
                  }

                // merge auth and db user
                authUser = {
                  uid: authUser.uid,
                  email: authUser.email,
                  emailVerified: authUser.emailVerified,
                  providerData: authUser.providerData,
                  ...dbUser,
                };

                next(authUser);
              });
        } else {
          fallback();
        }
      });

  // Data access APIs
    adminAccount = uid => this.db.doc(`admin_account/${uid}`);
    deploymentAccounts = (deploymentId) => this.db.collection(`deployment_account`).where('deploymentId', '==', deploymentId);
    deploymentAccount = deviceUserIdentifier => this.db.doc(`deployment_account/${deviceUserIdentifier}`);
    deployment = pid => this.db.doc(`deployments/${pid}`);
    deployments = () => this.db.collection('deployments');
    getCohortStats = (deploymentId, deviceUserIdentifier = null) =>
        cloudFunction(this, `getCohortStats?deploymentId=${deploymentId}&deviceUserIdentifier=${deviceUserIdentifier || ''}`);

    getActivitiesCompletedOverTime = (deploymentId, deviceUserIdentifier = null) =>
        cloudFunction(this, `getActivitiesCompletedOverTime?deploymentId=${deploymentId}&deviceUserIdentifier=${deviceUserIdentifier || ''}`);

    getComprehensiveAssessmentScoresOverTime = (deploymentId, deviceUserIdentifier) =>
        cloudFunction(this, `getComprehensiveAssessmentScoresOverTime?deploymentId=${deploymentId}&deviceUserIdentifier=${deviceUserIdentifier || ''}`);

    getPerLessonGroupAssessmentScores = (deploymentId, deviceUserIdentifier) =>
        cloudFunction(this, `getPerLessonGroupAssessmentScores?deploymentId=${deploymentId}&deviceUserIdentifier=${deviceUserIdentifier || ''}`);

    getActivityDurationMetrics = (deploymentId, deviceUserIdentifier, activityType) =>
        cloudFunction(this, `getActivityDurations?deploymentId=${deploymentId}&deviceUserIdentifier=${deviceUserIdentifier || ''}&activityName=${activityType || ''}`);

}

export default Firebase;