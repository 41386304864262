import React from 'react';
import {withFirebase} from "../../util/Firebase";
import {ClipLoader} from "react-spinners";
import {CohortProgress} from './sections/cohortProgress';
import ActivitiesCompletedOverTime from './sections/activitiesCompletedOverTime';
import ComprehensiveAssessmentScores from './sections/comprehensiveAssessmentScores';
import PerLessonGroupAssessmentScores from './sections/perLessonGroupAssessmentScores';
import IndividualLearnerProgress from './sections/individualLearnerProgress';

import Jumbotron from './../../components/Jumbotron/jumbotron.js';
import {useNonIdleRefresh} from "../../util/hooks";

const Deployment = ({match, firebase}) => {
    const deploymentId = match.params.deploymentId;
    const getData = () => firebase.deployment(deploymentId).get().then(d => d.data());
    const deployment = useNonIdleRefresh(getData);
    if (deployment === null) {
        return <div className="text-center"><ClipLoader size={100} /></div>
    }
    return <>
        <Jumbotron title={`Deployment Dashboard: ${deployment.name}`}
                   description={deployment.description}
                   image={'mbr-1920x1271.jpg'}
        />
        <div className="container">
            <CohortProgress counters={deployment.counters} />
            <hr className="mt-5 mb-5" />
            <ActivitiesCompletedOverTime deploymentId={deploymentId} />
            <hr className="mt-5 mb-5" />
            <ComprehensiveAssessmentScores deploymentId={deploymentId} />
            <hr className="mt-5 mb-5" />
            <PerLessonGroupAssessmentScores deploymentId={deploymentId}/>
            <hr className="mt-5 mb-5" />
            <IndividualLearnerProgress deploymentId={deploymentId}/>
        </div>
    </>

};
export default withFirebase(Deployment);
