import {ClipLoader} from "react-spinners";
import React from "react";
import {withFirebase} from "../../../util/Firebase";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useNonIdleRefresh} from "../../../util/hooks";

const AreaGraph = ({data}) => {
        return (
            <ResponsiveContainer width='75%' height={350} className='ml-auto mr-auto'>
                <AreaChart
                    data={data}
                    margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis/>
                    <Tooltip />
                    <Area type="monotone" dataKey="count" stackId="1" stroke="#ffc658" fill="#ffc658" />
                </AreaChart>
            </ResponsiveContainer>
        );
};

const ActivitiesCompletedOverTime = ({firebase, deploymentId, deviceUserIdentifier=null}) => {
    const getData = () => firebase.getActivitiesCompletedOverTime(deploymentId, deviceUserIdentifier);
    const data = useNonIdleRefresh(getData);
    return <div className='text-center'>
        <h1>Activities completed over time</h1>
        <h5 className="text-muted mb-5">Total number of activities completed over time</h5>
        {data === null && <ClipLoader size={75} />}
        {data && data.length === 0 && <span>No data yet!</span>}
        {data && data.length > 0 && <AreaGraph data={data} />}
    </div>

};
export default withFirebase(ActivitiesCompletedOverTime);