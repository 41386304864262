import React from 'react';
import {connect} from "react-redux";
import LandingAuth from "./loggedIn";
import LandingNonAuth from "./loggedOut";


const Landing = ({ authUser }) =>
    authUser ? (
        <LandingAuth authUser={authUser} />
    ) : (
        <LandingNonAuth />
    );


const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Landing);
