import React from 'react';

import { withFirebase } from '../../util/Firebase';
import * as ROUTES from "../../constants/routes";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";

const SignOutButton = ({ firebase, history }) => {
    const onSignOut = () => {
        firebase.doSignOut();
        history.push(ROUTES.SIGN_IN);
    };
    return <button className="btn btn-outline-primary" onClick={onSignOut}>
        Sign out
    </button>
};

export default compose(
    withRouter,
    withFirebase,
)(SignOutButton);

