import React, {useEffect, useRef, useState} from 'react';
import {withFirebase} from "../../util/Firebase";
import Jumbotron from "../../components/Jumbotron/jumbotron";
import {ClipLoader} from "react-spinners";
import ActivitiesCompletedOverTime from "../Deployment/sections/activitiesCompletedOverTime";
import ComprehensiveAssessmentScores from "../Deployment/sections/comprehensiveAssessmentScores";
import PerLessonGroupAssessmentScores from "../Deployment/sections/perLessonGroupAssessmentScores";
import CohortProgressForUser from './cohortProgressForUser';
import ReactToPrint from 'react-to-print';
import WordsLearnedByUser from "./wordsLearnedByUser";
import WordsLearnedByUserPrintable from "./wordsLearnedByUserPrintable";
import {getHashedUsername, PERMISSION} from "../../util/permissions";
import {compose} from "recompose";
import {connect} from "react-redux";
import TimeSpent from "./timeSpent";

class PrintableCohortProgressForUser extends React.Component {
    // this needs to be a component for ReactToPrint, which requires a ref (functional components in react don't
    // allow refs)
    render() {
        return <div>
            <CohortProgressForUser {...this.props} withSpanishTranslation={true} />
            <WordsLearnedByUserPrintable {...this.props} withSpanishTranslation={true} />
            </div>
    }
}

const DeploymentUser = (props) => {
    const {deploymentId, deviceUserIdentifier} = props.match.params;
    const printRef = useRef();

    const [account, setAccount] = useState(null);
    useEffect(() => {
        props.firebase.deploymentAccount(deviceUserIdentifier)
            .get()
            .then(d => {
                const permissionLevel = props.authUser.deployments[deploymentId];
                const accountData = d.data();
                setAccount({
                    ...accountData,
                    username: permissionLevel === PERMISSION.ADMIN ? accountData.username : getHashedUsername(accountData.username)
                });
            });
    }, [deviceUserIdentifier, deploymentId]);

    if (account === null) {
        return <div className="text-center"><ClipLoader size={100}/></div>
    }

    return <>
        <div className='text-right float-right align-top'>
            <ReactToPrint
                trigger={() => <button className='btn btn-link' onClick={() => window.print()}>Print</button>}
                content={() => printRef.current}
                pageStyle={`body { padding: 5%; }`}
            />
        </div>
        <Jumbotron title={`Learner Progress: ${account.username}`}
                   image={'mbr-1920x1271.jpg'}
        />
        <div className='container'>
            <CohortProgressForUser account={account} deploymentId={deploymentId}
                                   deviceUserIdentifier={deviceUserIdentifier} />
            <hr className="mt-5 mb-5" />
            <WordsLearnedByUser account={account} />
            <hr className="mt-5 mb-5" />
            <ActivitiesCompletedOverTime deploymentId={deploymentId} deviceUserIdentifier={deviceUserIdentifier} />
            <hr className="mt-5 mb-5" />
            <ComprehensiveAssessmentScores deploymentId={deploymentId} deviceUserIdentifier={deviceUserIdentifier} />
            <hr className="mt-5 mb-5" />
            <PerLessonGroupAssessmentScores deploymentId={deploymentId} deviceUserIdentifier={deviceUserIdentifier }/>
            <hr className="mt-5 mb-5" />
            <TimeSpent deploymentId={deploymentId} deviceUserIdentifier={deviceUserIdentifier } />

        </div>
        <div style={{display: 'none'}}>
            <PrintableCohortProgressForUser account={account}
                                            deploymentId={deploymentId}
                                            deviceUserIdentifier={deviceUserIdentifier} ref={printRef}/>
        </div>
    </>
};

const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
});


export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        null
    ),
)(DeploymentUser);