import React from 'react';
import Jumbotron from "../../components/Jumbotron/jumbotron";

const resources = () => {
    return <>
        <Jumbotron title={"GLEN Learn: Deployment Guidelines and Resources"}
                   description={"Recommended best practices and resources for content deployment"}
                   image={'resources2_banner.jpg'}
        />
        <div className='container'>
            <ul>
                <li className="h5"><a
                    href="https://drive.google.com/file/d/1910dxgs50LLuuaeAoG8fnjRZr3BQYQSK/view?usp=sharing"
                    target='_blank' rel='noopener noreferrer'>Pilot Deployment: Recommended Plan</a></li>
                <li className="h5"><a
                    href="https://drive.google.com/file/d/1941rmZdmNkspxJcUtPVCxcFszy8pfOQo/view?usp=sharing"
                    target='_blank' rel='noopener noreferrer'>Guidelines for Facilitators</a></li>
                <li className="h5"><a
                    href="https://drive.google.com/file/d/19Psgh_znWZJ6ajhc_Lm7udVlUq6yNp-z/view?usp=sharing"
                    target='_blank' rel='noopener noreferrer'>Frequently Asked Questions</a></li>
            </ul>
        </div>
    </>
};

export default resources