import React, {useEffect, useState} from "react";
import {withFirebase} from "../../util/Firebase";
import {Link} from "react-router-dom";
import {ClipLoader} from "react-spinners";

const DeploymentList = ({deployments}) => {
    return <div>
        <p className='h6'>Select a deployment below to view your dashboard.</p>
        <hr />
        <div className="row">
            {deployments.map(p => {
                return <div className="card col-12 col-lg-4" key={p.name}>
                    <div className="card-body">
                        <h5 className="card-title">{p.name}</h5>
                        <p className="card-text">{p.description}</p>
                        <Link to={'deployment/' + p.id} className="btn btn-primary">Visit dashboard</Link>
                    </div>
                </div>
            })}
        </div>
    </div>
};

const LandingAuth = ({ authUser, firebase }) => {
    const [deployments, setDeployments] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    //gets the deployments for this user.
    useEffect(() => {
        const getDeployments = async (deploymentIds) => {
            const refs = deploymentIds.map(id => firebase.deployment(id).get().then(d => {
                return {id: d.id, ...d.data()}
            }));
            return Promise.all(refs);
        };

        const deploymentIds = Object.keys(authUser.deployments);
        if (deploymentIds.length > 0) {
            getDeployments(deploymentIds).then(deployments => {
                setDeployments(deployments);
                setIsLoaded(true)
            });
        } else {
            setIsLoaded(true);
        }
    }, [authUser.deployments, firebase]);

    if (!isLoaded) {
        return <div className="text-center"><ClipLoader size={100} /></div>
    }

    return (
        <div className='container text-center'>
            <div className=" px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                <img className="mb-3" src="/GWlogo_spiral_300x195.png" alt="" width="100" height="66"/>
                <h1>Welcome {authUser.username}!</h1>
                {!deployments.length && <p>It looks like you don't have any deployments yet. Please e-mail us at apps@glenworld.org to get
                    started.</p> }
                {deployments.length > 0 && <DeploymentList deployments={deployments} />}
            </div>
        </div>);
};

export default withFirebase(LandingAuth)